import { render, staticRenderFns } from "./luckySport.vue?vue&type=template&id=5e90521e&scoped=true&"
import script from "./luckySport.vue?vue&type=script&lang=js&"
export * from "./luckySport.vue?vue&type=script&lang=js&"
import style0 from "./luckySport.vue?vue&type=style&index=0&id=5e90521e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e90521e",
  null
  
)

export default component.exports